<div class="tax-invoice-details">
  @if (showTitle) {
    <div class="title-wrapper">
      <div class="title">
        {{ "APP.EVENT_PAYMENT.TAX_INVOICE_DETAILS_AND_ADDRESS" | translate }}*
      </div>
    </div>
  }

  @if (form) {
    <form [formGroup]="form" class="form w-100" [class.generic]="isGeneric">
      <div class="field-row">
        <div class="radio-field">
          <label class="checkbox-container">
            <input
              class="checkbox-input"
              type="checkbox"
              [ngModel]="
                form.controls['type'].value === BillingAddressType.PERSONAL
              "
              [ngModelOptions]="{ standalone: true }"
              (click)="setIsCompany(false)"
            />
            <div class="checkbox-border">
              @if (
                form.controls["type"].value === BillingAddressType.PERSONAL
              ) {
                <div class="checkbox-filling"></div>
              }
            </div>
            <ng-content></ng-content>
          </label>
          <div>{{ "APP.EVENT_PAYMENT.PRIVATE" | translate }}</div>
        </div>
        <div class="radio-field">
          <label class="checkbox-container">
            <input
              class="checkbox-input"
              type="checkbox"
              [ngModel]="
                form.controls['type'].value === BillingAddressType.COMPANY
              "
              [ngModelOptions]="{ standalone: true }"
              (click)="setIsCompany(true)"
            />
            <div class="checkbox-border">
              @if (form.controls["type"].value === BillingAddressType.COMPANY) {
                <div class="checkbox-filling"></div>
              }
            </div>
            <ng-content></ng-content>
          </label>
          <div>{{ "APP.EVENT_PAYMENT.COMMERCIAL" | translate }}</div>
        </div>
      </div>

      @if (form.controls["type"].value === BillingAddressType.PERSONAL) {
        <mat-form-field
          [appearance]="appearance"
          class="flex-input divide-bottom"
        >
          <mat-label
            >{{ "APP.EVENT_PAYMENT.INVOICE_RECIPIENT" | translate }}
            {{ getAsteriskIfRequired("invoiceRecipient") }}</mat-label
          >
          <input
            type="text"
            matInput
            formControlName="invoiceRecipient"
            placeholder=""
          />
        </mat-form-field>
      } @else {
        <mat-form-field [appearance]="appearance" class="flex-input">
          <mat-label
            >{{ "APP.EVENT_PAYMENT.COMPANY_NAME" | translate }}
            {{ getAsteriskIfRequired("companyName") }}</mat-label
          >
          <input
            type="text"
            matInput
            formControlName="companyName"
            placeholder=""
          />
        </mat-form-field>
        <mat-form-field
          [appearance]="appearance"
          class="flex-input divide-bottom"
        >
          <mat-label
            >{{ "APP.EVENT_PAYMENT.VAT_NUMBER" | translate }}
            {{ getAsteriskIfRequired("vatNumber") }}</mat-label
          >
          <input
            type="text"
            matInput
            formControlName="vatNumber"
            placeholder=""
          />
        </mat-form-field>
      }
      <div class="form-row">
        <mat-form-field [appearance]="appearance" class="flex-input w-70">
          <mat-label
            >{{ "APP.EVENT_PAYMENT.ADDRESS" | translate }}
            {{ getAsteriskIfRequired("address") }}</mat-label
          >
          <input
            #addressInput
            type="text"
            matInput
            formControlName="address"
            placeholder=""
          />
        </mat-form-field>
        <mat-form-field [appearance]="appearance" class="flex-input w-30">
          <mat-label
            >{{ "APP.EVENT_PAYMENT.ADDRESS_ADDITION" | translate }}
            {{ getAsteriskIfRequired("addressAddition") }}</mat-label
          >
          <input
            type="text"
            matInput
            formControlName="addressAddition"
            placeholder=""
          />
        </mat-form-field>
      </div>
      <div class="form-row">
        <mat-form-field [appearance]="appearance" class="flex-input w-50">
          <mat-label
            >{{ "APP.EVENT_PAYMENT.CITY" | translate }}
            {{ getAsteriskIfRequired("locality") }}</mat-label
          >
          <input
            type="text"
            matInput
            formControlName="locality"
            placeholder=""
          />
        </mat-form-field>
        @if (!isGeneric) {
          <mat-form-field [appearance]="appearance" class="flex-input w-50">
            <mat-label
              >{{ "APP.EVENT_PAYMENT.STATE" | translate }}
              {{ getAsteriskIfRequired("state") }}</mat-label
            >
            <input
              type="text"
              matInput
              formControlName="federalState"
              placeholder=""
            />
          </mat-form-field>
        }
      </div>
      <div class="form-row">
        <mat-form-field [appearance]="appearance" class="flex-input w-30">
          <mat-label
            >{{ "APP.EVENT_PAYMENT.POSTAL_CODE" | translate }}
            {{ getAsteriskIfRequired("postalCode") }}</mat-label
          >
          <input
            type="text"
            matInput
            formControlName="postalCode"
            placeholder=""
          />
        </mat-form-field>
        <mat-form-field [appearance]="appearance" class="flex-input w-70">
          <mat-label
            >{{ "APP.EVENT_PAYMENT.COUNTRY" | translate }}
            {{ getAsteriskIfRequired("country") }}</mat-label
          >
          <input
            type="text"
            matInput
            formControlName="country"
            placeholder=""
          />
          <mat-hint class="form-hint w-100">{{
            "APP.EVENT_PAYMENT.REQUIRED_FIELD" | translate
          }}</mat-hint>
        </mat-form-field>
      </div>
    </form>
  }
</div>
